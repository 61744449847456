<script>
import { onMounted } from 'vue'
import { useStore } from '../../store'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    section: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const store = useStore()
    
    onMounted(() => {
      const elems = document.getElementById( props.section )
      store.storeAJAX({
        method: 'get',
        url: props.url
      })
        .then((response) => {
          const html = document.createElement('div')
          html.innerHTML = response.data
          const recommendations = html.querySelector( '#' + props.section )
          elems.innerHTML = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          
        })
    })

    return () => slots.default()
  }
}
</script>