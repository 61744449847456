<script setup>
import { toRef, ref, onMounted, onUnmounted, watch } from 'vue'
import lozad from 'lozad'

const props = defineProps({
  contents: {
    type: Object,
    default: {},
  }
})

const initial   = toRef(props, 'contents')
const contents  = ref(initial.value)
const loading   = ref(true)
const image     = ref(null)

const htmlDecode  = ( str ) => {
  var doc = new DOMParser().parseFromString( str, 'text/html' )
  return doc.documentElement.textContent
}

const setLoadingState = () => {
  loading.value = false
}

const setImage = () => {
  image.value.addEventListener( 'load', setLoadingState )
  const observer = lozad( image.value, {
    enableAutoReload: true
  } )
  observer.observe()
}

const clearImage = () => {
  image.value.removeAttribute( 'data-loaded' )
  setImage()
}

onMounted(() => {
  setImage()
})

onUnmounted(() => {
  image.value.removeEventListener( 'load', setLoadingState )
})

watch( props, ( value ) => {
  image.value.removeEventListener( 'load', setLoadingState )
  contents.value = value.contents
  loading.value  = true
  setTimeout( function() {
    clearImage()
  }, 500 )
})
</script>

<template>
  <div v-if="loading" class="absolute w-full h-full top-0 left-0 flex flex-wrap justify-center items-center">
    <svg class="inline-block w-16 h-auto text-neutral-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 38" preserveAspectRatio="xMidYMid"><g transform="translate(20 20)"><circle cx="0" cy="0" r="6" class="fill-current" ><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g><g transform="translate(40 20)"><circle cx="0" cy="0" r="6" class="fill-current" ><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g><g transform="translate(60 20)"><circle cx="0" cy="0" r="6" class="fill-current" ><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g><g transform="translate(80 20)"><circle cx="0" cy="0" r="6" class="fill-current" ><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g></svg>
  </div>
  <img ref="image" :data-src="htmlDecode( contents.src )" :data-srcset="htmlDecode( contents.srcset )" :sizes="contents.sizes" :class="[ contents.class, 'transition-opacity', ( loading ? 'opacity-0' : 'opacity-100' ) ]" loading="lazy" :width="contents.width" :height="contents.height" />
</template>