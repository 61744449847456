<script>
import { ref } from 'vue'
import { FreeMode, Navigation, Thumbs } from 'swiper'

export default {
  setup(props, { slots }) {
    const thumbsSwiper    = ref(false)
    const setThumbsSwiper = (swiper) => thumbsSwiper.value = swiper

    return () => slots.default({
      modules: [FreeMode, Navigation, Thumbs],
      thumbsSwiper: thumbsSwiper.value,
      setThumbsSwiper
    })
  }
}
</script>