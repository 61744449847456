<script>
import { toRef, ref } from 'vue'
import { debounce } from 'lodash'
import { useStore } from '../../store'
// import suggest from '../../suggest.json'

export default {
  props: {
    types: {
      type: String,
      default: 'product'
    },
    limit: { 
      type: Number,
      default: 0
    },
    keyword: {
      type: String,
      required: true,
      default: ''
    },
    filters: {
      type: Object,
      default: {}
    },
    colours: {
      type: Array,
      default: []
    }
  },
  setup(props, { slots }) {
    const store       = useStore()
    const loading     = ref(false)
    const active      = ref(false)
    const focus       = ref(false)
    const searching   = ref(false)
    const initialK    = toRef(props, 'keyword')
    const initialF    = toRef(props, 'filters')
    const initialC    = toRef(props, 'colours')
    const keyword     = ref(initialK.value)
    const filters     = ref(initialF.value)
    const colours     = ref(initialC.value)
    const results     = ref({})
    const toggle      = () => active.value = ! active.value
    const filterParam = (type, param) => store.filterParam(type, param)
    const htmlDecode  = (str) => store.htmlDecode(str)
    const getHexCode  = (name) => store.getHexCode(colours.value, name)
    const formatMoney = (cents) => store.formatMoney(cents)

    const onSearch  = (q) => {
      if ( q.length > 0 ) {
        keyword.value   = q
        searching.value = true
        focus.value     = true

        store.storeAJAX({
          method: 'get',
          url: '/search/suggest.json',
          params: {
            'q': q,
            'resources[type]': props.types,
            'resources[limit]': props.limit
          }
        })
        .then((response) => {
          results.value   = response.data.resources.results
          searching.value = false
        })
        .catch((error) => {
          // results.value   = suggest.resources.results
          results.value   = false
          searching.value = false
        })
        .finally(() => {
          
        })
      } else {
        keyword.value   = initialK.value
        results.value   = ''
        searching.value = false
        focus.value     = false
      }
    }

    const onFilterChange = (reset = false) => {
      loading.value   = true
      const formData  = ref( new FormData(document.getElementById('filtersForm')) )
      const range_max = filters.value.range_max / 100
      const min       = parseInt(formData.value.get('filter.v.price.gte'))
      const max       = parseInt(formData.value.get('filter.v.price.lte'))

      if (reset) {
        formData.value = new FormData()
      }

      if (min === 0 && max === range_max) {
        formData.value.delete('filter.v.price.gte')
        formData.value.delete('filter.v.price.lte')
      }
      
      const searchParams = new URLSearchParams(formData.value).toString()
      renderPage(searchParams)
    }

    const renderPage = (searchParams) => {
      const url     = `${window.location.pathname}?${searchParams}`
      const request = new XMLHttpRequest()

      request.onreadystatechange = function success() {
        if (request.readyState === 4 && request.status === 200) {
          const newContainer  = request.responseXML.getElementById('products')
          const newPagination = request.responseXML.getElementById('pagination')
          const newfilters    = request.responseXML.getElementById('filtersForm')
          document.getElementById('products').innerHTML    = newContainer.innerHTML
          document.getElementById('pagination').innerHTML  = newPagination.innerHTML
          filters.value = JSON.parse( newfilters.getAttribute('data-filters') )
          loading.value = false
        }
      };

      request.open( 'GET', url )
      request.responseType = 'document'
      request.send()

      if ( window.innerWidth < 768 ) {
        active.value = false
      }

      history.pushState({ searchParams }, '', `${window.location.pathname}${searchParams && '?'.concat(searchParams)}`)
    }

    return () => slots.default({
      loading: loading.value,
      active: active.value,
      focus: focus.value,
      searching: searching.value,
      filters: filters.value,
      keyword: keyword.value,
      results: results.value,
      toggle,
      filterParam,
      htmlDecode,
      getHexCode,
      formatMoney,
      search: debounce((q) => onSearch(q), 500),
      filterChange: debounce((bool) => onFilterChange(bool), 500)
    })
  }
}
</script>