<script>
import { toRef, ref } from 'vue'
import { debounce } from 'lodash'
import { useStore } from '../../store'

export default {
  props: {
    filters: {
      type: Object,
      default: {}
    },
    colours: {
      type: Object,
      default: {}
    }
  },
  setup(props, { slots }) {
    const store       = useStore()
    const active      = ref(false)
    const loading     = ref(false)
    const initialF    = toRef(props, 'filters')
    const initialC    = toRef(props, 'colours')
    const filters     = ref(initialF.value)
    const colours     = ref(initialC.value)
    const toggle      = () => active.value = ! active.value
    const filterParam = (type, param) => store.filterParam(type, param)
    const htmlDecode  = (str) => store.htmlDecode(str)
    const getHexCode  = (name) => store.getHexCode(colours.value, name)
    const formatMoney = (cents) => store.formatMoney(cents)

    const onFilterChange = (reset = false) => {
      loading.value   = true
      const formData  = ref( new FormData(document.getElementById('filtersForm')) )
      const range_max = filters.value.range_max / 100
      const min       = parseInt(formData.value.get('filter.v.price.gte'))
      const max       = parseInt(formData.value.get('filter.v.price.lte'))

      if (reset) {
        formData.value = new FormData()
      }

      if (min === 0 && max === range_max) {
        formData.value.delete('filter.v.price.gte')
        formData.value.delete('filter.v.price.lte')
      }
      
      const searchParams = new URLSearchParams(formData.value).toString()
      renderPage(searchParams)
    }

    const renderPage = (searchParams) => {
      const url     = `${window.location.pathname}?${searchParams}`
      const request = new XMLHttpRequest()

      request.onreadystatechange = function success() {
        if (request.readyState === 4 && request.status === 200) {
          const newContainer  = request.responseXML.getElementById('products')
          const newPagination = request.responseXML.getElementById('pagination')
          const newfilters    = request.responseXML.getElementById('filtersForm')
          document.getElementById('products').innerHTML    = newContainer.innerHTML
          document.getElementById('pagination').innerHTML  = newPagination.innerHTML
          filters.value = JSON.parse( newfilters.getAttribute('data-filters') )
          loading.value = false
        }
      };

      request.open( 'GET', url )
      request.responseType = 'document'
      request.send()

      if ( window.innerWidth < 768 ) {
        active.value = false
      }

      history.pushState({ searchParams }, '', `${window.location.pathname}${searchParams && '?'.concat(searchParams)}`)
    }

    return () => slots.default({
      active: active.value,
      loading: loading.value,
      filters: filters.value,
      toggle,
      filterParam,
      htmlDecode,
      getHexCode,
      formatMoney,
      filterChange: debounce((bool) => onFilterChange(bool), 500)
    })
  }
}
</script>