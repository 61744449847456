<script>
import { useStore } from '../../store'

export default {
  setup(props, { slots }) {
    const store  = useStore()
    const toggle = (key) => store.drawers[key] = ! store.drawers[key]
    return () => slots.default({
      active: store.drawers,
      toggle
    })
  }
}
</script>