<script>
import { toRef, ref, onMounted } from 'vue'

export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const url   = toRef(props, 'url')
    const link  = ref(url.value)
    
    onMounted(() => {
      window.location.href = link.value
    })

    return () => slots.default()
  }
}
</script>