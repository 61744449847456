<script>
import { ref } from 'vue'
import { useStore } from '../../store'

export default {
  setup(props, { slots }) {
    const store       = useStore()
    const loading     = ref(false)
    const formatMoney = (cents) => store.formatMoney(cents)
    const remove      = (id) => updateCart(id, 0)
    const toggle      = () => {
      if (! store.drawers.cart) { store.getCart() }
      store.drawers.cart = ! store.drawers.cart
    }

    const updateCart = (id, qty) => {
      loading.value = true
      const count   = document.getElementById( 'cart_count' )
      const formD   = new FormData()
      formD.append('id', id)
      formD.append('quantity', qty)

      store.storeAJAX({
        method: 'post',
        url: '/cart/change.js',
        data: formD
      })
        .then((response) => {
          store.cart = response.data
          count.innerHTML = response.data.item_count
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          loading.value = false
        })
    }
    
    return () => slots.default({
      loading: loading.value,
      active: store.drawers.cart,
      cart: store.cart,
      toggle,
      remove,
      formatMoney
    })
  }
}
</script>