<script setup>
import { ref, toRef, onMounted, watch } from 'vue'
import { useStore } from '../../store'

const props = defineProps({
  range_max: {
    type: Number,
    default: 0
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 0
  },
  min_name: {
    type: String,
    default: ''
  },
  max_name: {
    type: String,
    default: ''
  }
})

const store       = useStore()
const range_max   = toRef(props, 'range_max')
const mins        = toRef(props, 'min')
const maxs        = toRef(props, 'max')
const min         = ref(mins.value)
const max         = ref(maxs.value)
const left        = ref()
const right       = ref()
const range       = ref()
const min_thumb   = ref()
const max_thumb   = ref()

const onMin = () => {
  let minPercent = ( min.value / range_max.value ) * 100

  left.value.style.width = minPercent + '%'
  range.value.style.left  = minPercent + '%'
  min_thumb.value.style.left  = minPercent + '%'
}

const onMax = () => {
  let maxPercent = ( max.value / range_max.value ) * 100

  right.value.style.width = ( 100 - maxPercent ) + '%'
  range.value.style.right  = ( 100 - maxPercent ) + '%'
  max_thumb.value.style.left  = maxPercent + '%'
}

onMounted(() => {
  onMin()
  onMax()
})

watch( props, ( value ) => {
  min.value = value.min
  max.value = value.max
  onMin()
  onMax()
})
</script>

<template>
  <div class="range-slider relative h-10 rounded-xl text-left">
    <div class="absolute left-2 right-2 h-4">
      <div ref="left" class="absolute left-0 top-1/2 h-[3px] -mt-[1.5px] rounded-xl bg-neutral-400" style="width: 0%;"></div>
      <div ref="right" class="absolute right-0 top-1/2 h-[3px] -mt-[1.5px] rounded-xl bg-neutral-400" style="width: 0%;"></div>
      <div ref="range" class="absolute left-0 top-1/2 h-[3px] -mt-[1.5px] rounded-xl bg-black" style="left: 0%; right: 0%;"></div>
      <span ref="min_thumb" class="absolute top-1/2 w-4 h-4 -mt-2 -ml-2 text-left bg-black rounded-3xl outline-none shadow-md cursor-pointer z-10" style="left: 0%;"></span>
      <span ref="max_thumb" class="absolute top-1/2 w-4 h-4 -mt-2 -ml-2 text-left bg-black rounded-3xl outline-none shadow-md cursor-pointer z-10" style="left: 100%;"></span>
      <div class="absolute left-0 -bottom-8 -ml-2 w-auto h-7 text-center text-black leading-7 md:leading-7">
        <span v-html="store.formatMoney(min * 100)" />
      </div>
      <div class="absolute right-0 -bottom-8 -mr-2 w-auto h-7 text-center text-black leading-7 md:leading-7">
        <span v-html="store.formatMoney(max * 100)" />
      </div>
    </div>
    <input
      type="range"
      tabindex="0"
      min="0"
      :max="range_max"
      step="1"
      :name="min_name"
      :id="'Filter-' + min_name"
      v-model="min"
      v-on:input="onMin()"
    />
    <input
      type="range"
      tabindex="0"
      min="0"
      :max="range_max"
      step="1"
      :name="max_name"
      :id="'Filter-' + max_name"
      v-model="max"
      v-on:input="onMax()"
    />
  </div>
</template>