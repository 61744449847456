<script>
import { toRef, ref } from 'vue'

export default {
  props: {
    active: { 
      type: Boolean,
      default: false
    }
  },
  setup(props, { slots }) {
    const initial = toRef(props, 'active')
    const active  = ref(initial.value)
    const toggle  = () => active.value = ! active.value
    
    return () => slots.default({
      active: active.value,
      toggle
    })
  }
}
</script>