<script>
import { ref, toRef } from 'vue'

export default {
  props: {
    active: {
      type: String,
      required: true,
      default: ''
    },
    hide: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { slots }) {
    const initialA  = toRef(props, 'active')
    const active    = ref(initialA.value)
    const initialH  = toRef(props, 'hide')
    const hide      = ref(initialH.value)
    const toggle    = (payload) => {
      if ( hide.value && active.value === payload ) {
        active.value = ''
      } else {
        active.value = payload
      }
    }

    return () => slots.default({
      active: active.value,
      toggle
    })
  }
}
</script>