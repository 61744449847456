<script>
import { ref } from 'vue'
import { debounce } from 'lodash'
import { useStore } from '../../store'
// import suggest from '../../suggest.json'

export default {
  props: {
    types: {
      type: String,
      default: 'product'
    },
    limit: { 
      type: Number,
      default: 0
    }
  },
  setup(props, { slots }) {
    const store     = useStore()
    const loading   = ref(false)
    const focus     = ref(false)
    const keyword   = ref('')
    const results   = ref({})
    const onSearch  = (q) => {
      if ( q.length > 0 ) {
        keyword.value = q
        loading.value = true
        focus.value   = true

        store.storeAJAX({
          method: 'get',
          url: '/search/suggest.json',
          params: {
            'q': q,
            'resources[type]': props.types,
            'resources[limit]': props.limit
          }
        })
        .then((response) => {
          results.value = response.data.resources.results
          loading.value = false
        })
        .catch((error) => {
          // results.value = suggest.resources.results
          results.value = false
          loading.value = false
        })
        .finally(() => {
          
        })
      } else {
        keyword.value = ''
        results.value = ''
        loading.value = false
        focus.value   = false
      }
    }
    
    const toggleFocus = (bool) => focus.value = bool
    const goToSearch  = (q) => window.location.href = '/search?q=' + q

    return () => slots.default({
      loading: loading.value,
      focus: focus.value,
      keyword: keyword.value,
      results: results.value,
      search: debounce((q) => onSearch(q), 500),
      toggleFocus,
      goToSearch
    })
  }
}
</script>