<script setup>
import { ref, toRef } from 'vue'
import LazyLoad from './LazyLoad.vue'

const props = defineProps({
  contents: {
    type: Object,
    default: {}
  }
})

const initial = toRef(props, 'contents')
const brands  = ref(initial.value.brands)
const active  = ref(initial.value.featured)
const change  = ( brand ) => active.value  = brand
</script>

<template>
  <div class="w-full">
    <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6">
      <div v-if="active" class="relative hidden md:block">
        <div v-if="active.image" class="relative flex flex-nowrap flex-col items-center justify-center md:sticky md:top-[67px] md:h-[calc(100vh-83px)]">
          <lazy-load :contents="active.image" />
        </div>
      </div>
      <div v-if="brands" class="border-b border-neutral-400">
        <div v-for="group, key in brands" :key="key" class="grid grid-cols-3 gap-3 py-2 border-t border-neutral-400 md:gap-6 md:py-4">
          <div class="pl-4 md:pl-0"><span v-html="key"></span></div>
          <div class="col-span-2">
            <ul class="grid grid-cols-1 gap-2 md:grid-cols-2">
              <li v-for="brand, idx in group" :key="idx">
                <a :href="brand.url" v-html="brand.title" v-on:mouseover="change(brand)" class="hover:line-through" :class="( active.handle === brand.handle ) ? 'line-through' : ''"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>