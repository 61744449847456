<script>
import { toRef, ref } from 'vue'
import { map, pickBy } from 'lodash'
import { onMounted } from 'vue'

export default {
  props: {
    charts: {
      type: Object,
      default: []
    },
    vendor: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const initialC    = toRef(props, 'charts')
    const initialV    = toRef(props, 'vendor')
    const charts      = ref(initialC.value)
    const vendor      = ref(initialV.value)
    const chart       = ref([])

    const changeHead  = (str) => {
        const strs = str.split(/(?=[A-Z])/)
        strs[0] = strs[0].toUpperCase()
        return strs.join(' ')
    }

    const checkContent = (index, str) => {
      if ( index === 0) {
        if (isNaN(str)) {
          if (str.split('').length > 4) {
            return 'font-medium !text-left'
          }
        }
      }
    }
    
    onMounted(() => {
      const sizes = map(pickBy(charts.value, function(value, key) {
        var key_handle = key.toLowerCase().replaceAll(' ', '-')
        return key_handle == vendor.value || key_handle.includes(vendor.value) || vendor.value.includes(key_handle)
      }), o => o)
      chart.value = sizes[0]
    })

    return () => slots.default({
      chart: chart.value,
      changeHead,
      checkContent
    })
  }
}
</script>