<script>
import { toRef, ref } from 'vue'
import { debounce, map, pickBy, find } from 'lodash'
import { useStore } from '../../store'

export default {
  props: {
    variations: {
      type: Array,
      default: []
    },
    input_id: {
      type: Number,
      default: 0
    },
    price: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const store       = useStore()
    const loading     = ref(false)
    const initialVr   = toRef(props, 'variations')
    const initialID   = toRef(props, 'input_id')
    const initialPr   = toRef(props, 'price')
    const variations  = ref(initialVr.value)
    const input_id    = ref(initialID.value)
    const price       = ref(initialPr.value)
    const options     = ref([])

    const onChangeOption = () => {
      const values    = map(pickBy(options.value, o => o.checked), o => o.value)
      const variation = find(variations.value, (o) => {
        return o.options.join(',') === values.join(',')
      })
      input_id.value = variation.id
      price.value    = variation.price_html
    }

    const onAddToCart = (event) => {
      loading.value = true
      store.storeAJAX({
        method: 'post',
        url: '/cart/add.js',
        data: new FormData(event.target)
      })
        .then((response) => {
          store.getCart()
          store.drawers.cart = true
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return () => slots.default({
      loading: loading.value,
      input_id: input_id.value,
      price: price.value,
      options: options.value,
      changeOption: debounce(() => onChangeOption(), 500),
      addToCart: debounce((event) => onAddToCart(event), 500)
    })
  }
}
</script>