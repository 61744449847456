<script>
import { ref, inject } from 'vue'

export default {
  props: {
    cookies: {
      type: Boolean,
      default: false
    },
    expiry: {
      type: String,
      default: '3'
    },
    browser: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { slots }) {
    const active    = ref(true)
    const $cookies  = inject('$cookies')

    if ( $cookies.get( 'age_verification' ) ) {
      active.value = false
    }

    if ( props.browser ) {
      window.addEventListener( 'beforeunload', function( e ) {
        $cookies.remove( 'age_verification' )
      }, false)
    }

    const closePopUp = () => {
      active.value = false

      if ( props.cookies ) {
        $cookies.set( 'age_verification', 'active', props.expiry + 'd' )
      }
    }

    const closeWindow = () => {
      if ( confirm('Close Window?') ) {
        close()
      }
    }
    
    return () => slots.default({
      active: active.value,
      closePopUp,
      closeWindow
    })
  }
}
</script>